.App {
  text-align: 'center';
  font-family: 'Questrial', sans-serif;
  font-family: 'Alice', serif;
}

.grecaptcha-badge {
  /* margin-top: 190px; */
  /* position: fixed; */
  /* bottom: -80px;
  background-color: transparent;
  /* width: 70px !important; */
  /* overflow: hidden !important; */
  /* transition: all 0.3s ease !important; */
  /* right: 4px !important; */
  /* text-align: left; */
  /* float: center;  */
  width: 20;
  display: none;
}

textarea:focus,
input:focus {
  outline: none;
}

input:focus,
input:focus {
  outline: none;
}

*:focus {
  outline: none;
}

/* ant-pagination-item-1 ant-pagination-item-active */

.ant-pagination-item-active {
  color: #4fb4e8;
  border-color: #4fb4e8;
}

ant-pagination-item
  ant-pagination-item-1
  ant-pagination-item-active
  .ant-pagination-item-1 {
  color: #4fb4e8ed;
  border-color: #4fb4e8;
}

.ant-pagination-item {
  color: #4fb4e8;
  border-color: #4fb4e8;
}

/* @primary-color: #1890ff; // primary color for all components
@link-color: #1890ff; // link color
@success-color: #52c41a; // success state color
@warning-color: #faad14; // warning state color
@error-color: #f5222d; // error state color
@font-size-base: 14px; // major text font size
@heading-color: rgba(0, 0, 0, 0.85); // heading text color
@text-color: rgba(0, 0, 0, 0.65); // major text color
@text-color-secondary: rgba(0, 0, 0, 0.45); // secondary text color
@disabled-color: rgba(0, 0, 0, 0.25); // disable state color
@border-radius-base: 2px; // major border radius
@border-color-base: #d9d9d9; // major border color
@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
  0 9px 28px 8px rgba(0, 0, 0, 0.05); // major shadow for layers */

.bottomNavigation {
  position: fixed;
  bottom: 0;
  width: 100vw;
  max-width: 100vw;
  padding-bottom: 30;
  padding-top: 0;
  height: 80;
  text-align: center;
  min-height: 80;
  border-top: 1px solid rgba(230, 240, 240, 0.99);
  background: rgba(255, 253, 253, 0.88);
  backdrop-filter: blur(8px);
  z-index: 9999999;
  font-family: 'sofia-pro', sans-serif;
  -webkit-backdrop-filter: blur(8px);
}

.makeStyles-root-2 .MuiButton-root {
  margin-top: 0 !important;
}

.iFrame {
  zoom: 0.2;
  width: 100%;
  height: 1800px;
}

.MuiAccordionSummary-root:hover:not(.Mui-disabled) {
  cursor: default !important;
}

.distanceLable {
  color: white;
  border-radius: 6px;
  line-height: 20px;
  width: 75px;
  height: 28px;
  float: right;
  letter-spacing: -0.24px;
  font-family: 'sofia-pro', sans-serif;
  font-size: 13px;
  background-color: #1c1c1c;
  opacity: 0.4;
  position: relative;
  font-weight: 600;
  align-items: center;
  text-align: center;
  margin-top: -123px;
  margin-right: 17px;
  padding-top: 4px;
  backdrop-filter: blur(61px);
  -webkit-backdrop-filter: blur(61px);
}

/* .grecaptcha-badge:hover {
  width: 256px !important;
} */

@font-face {
  font-family: 'Sofia Pro';
  font-style: normal;
  font-weight: 900;
  src: url('./Assets/Fonts/sofiapro-light.otf') format('otf');
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* New app css */

.done-button {
  background-color: #4fb4e8;
  color: white;
  border-radius: 16px;
  font-weight: bold;
  margin-top: 16px;
  height: 50px;
}

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: -100%;
  left: 0;
  bottom: 0;
  right: 0;
}

.anticon-caret-up ant-table-column-sorter-up :active {
  /* color: red !important */
}

/* .ant-picker-dropdown ant-picker-dropdown-range ant-picker-dropdown-placement-bottomRight {
  z-index: 99999999999999 !important;
  position: relative !important;
}

.ant-picker-range-wrapper ant-picker-date-range-wrapper {
  z-index: 99999999999999 !important;
  position: relative !important;
}

.ant-picker-range-wrapper {
  z-index: 99999999999999 !important;
  position: relative !important;
} */

.ant-picker-dropdown-placement-bottomRight {
  z-index: 99999999999999 !important;
  /* position: relative !important; */
}

ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  /* color: red !important; */
  background-color: #4fb4e8 !important;
}

/* .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background-color: #4fb4e8 !important;
} */

/* .ant-picker-cell-in-view.ant-picker-cell-in-range ant-picker-cell-range-hover-start {
  background-color: #4fb4e830 !important;
} */

#ant-picker-cell
  ant-picker-cell-in-view
  ant-picker-cell-in-range
  ant-picker-cell-range-hover {
  background-color: #4fb4e830 !important;
}

.ant-picker-cell-in-range::before {
  background-color: #4fb4e830 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background-color: #4fb4e830 !important;
}

.ant-picker-focused {
  border-color: #4fb4e830 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid #4fb4e8 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  )::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-range-end-single
  )::before {
  background-color: #4fb4e830 !important;
}

.ant-picker-focused {
  background-color: transparent !important;
  box-shadow: none !important;
  /* border: none !important; */
}

.ant-picker:hover,
.ant-picker-focused {
  /* border: none !important; */
}

.ant-picker-active-bar {
}

.ant-picker-range .ant-picker-active-bar {
  background-color: #4fb4e8 !important;
}

.ant-picker ant-picker-range {
  background-color: transparent !important;
}
/* .ant-picker-dropdown-range {
  z-index: 99999999999999 !important;
  position: relative !important;
} */

.ant-popover {
  z-index: 99999 !important;
}
